import { FC, MouseEventHandler } from "react";

import Card from "../../atoms/Card/Card";
import Tag from "../../atoms/Tag/Tag";
import { TripLocationCard } from "../../icons/UI/TripLocationCard";

interface ActiveTripGroupCardProps {
  id: string;
  index: number;
  volunteers: string[];
  locations: string[];
}

const ActiveTripGroupCard: FC<ActiveTripGroupCardProps> = ({
  volunteers,
  locations,
  id,
  index,
}) => {
  const handleClick: MouseEventHandler = () => {
    // temporary placeholder before adding navigation to group details
    console.log("TripGroupCard clicked");
  };

  const volunteersList = volunteers.join(", ");
  const locationsListLength = locations.length.toString();

  return (
    <div tabIndex={0} key={id} role="button" onClick={handleClick}>
      <Card
        header={volunteersList}
        content={
          <div className="group-trip__locations-container">
            <Tag tagData={locationsListLength} icon={<TripLocationCard />} />
          </div>
        }
        icon={<div className="group-trip__icon">{index}</div>}
      />
    </div>
  );
};
export default ActiveTripGroupCard;
