import { FC, useContext, useEffect } from "react";

import { useActiveTrip } from "../../api/activeTrip";
import ActiveTripDataSection from "../../components/atoms/ActiveTripDataSection/ActiveTripDataSection";
import LoadingSpinner from "../../components/atoms/LoadingSpinner/LoadingSpinner";
import PageHeader from "../../components/atoms/PageHeader/PageHeader";
import ActiveTripGroupsList from "../../components/organisms/ActiveTripGroupsList/ActiveTripGroupsList";
import { AppContext } from "../../context/AppContext";
import { formatDateToDDMMYY } from "../../utils/dateFormat";

const ActiveTrip: FC = () => {
  const { isLoading, data, isError } = useActiveTrip();

  const { addToast } = useContext(AppContext);
  const failureMsg = "Wystąpił problem z pobraniem danych wyjazdu";

  useEffect(() => {
    isError && addToast("error", failureMsg);
  }, [isError, addToast]);

  const tripDate = data && data.length > 0 ? formatDateToDDMMYY(data[0].date) : "";

  if (isLoading) {
    return (
      <div className="active-trip">
        <PageHeader>
          <LoadingSpinner />
        </PageHeader>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="active-trip">
        <PageHeader>
          <ActiveTripDataSection />
        </PageHeader>
        <div className="active-trip__network-error">
          <h2>Ups... coś poszło nie tak</h2>
          <p>Nie możemy wyświetlić szczegółów wyjazdu</p>
        </div>
      </div>
    );
  }

  return (
    <div className="active-trip">
      <PageHeader>{data && <ActiveTripDataSection tripDate={tripDate} />}</PageHeader>
      {data && <ActiveTripGroupsList trips={data} />}
    </div>
  );
};

export default ActiveTrip;
