import { FC, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import LocationAdditionalInfoCard from "../../components/atoms/LocationAdditionalInfoCard/LocationAdditionalInfoCard";
import NavigateButton from "../../components/atoms/NavigateButton/NavigateButton";
import PageHeader from "../../components/atoms/PageHeader/PageHeader";
import SectionHeader from "../../components/atoms/SectionHeader/SectionHeader";
import { LocationPinAvatar, SmileyFaceIcon } from "../../components/icons/Icons";
import PersonCard from "../../components/molecules/PersonCard/PersonCard";
import VisitSummariesSection from "../../components/organisms/VisitSummariesSection/VisitSummariesSection";
import { AppContext, StatusEnum } from "../../context/AppContext";

const LocationProfile: FC = () => {
  const { id } = useParams();
  const { fetchLocation, location } = useContext(AppContext);

  const isLoading = location.responseStatus === StatusEnum.Pending;
  const hasVisitSummaries =
    location.entities?.visitSummaries && location.entities?.visitSummaries.length > 0;
  const hasNavigationInfo =
    location.entities?.info || (location.entities?.latitude && location.entities.longitude);

  useEffect(() => {
    id && fetchLocation(id);
  }, [id]);

  return (
    <>
      <PageHeader>
        {isLoading ? (
          <div>Ładujemy dane...</div>
        ) : (
          <div>
            <div className="location-profile__name">
              <LocationPinAvatar className="location-profile__avatar" />
              {location.entities && <h2>{location.entities.fullName}</h2>}
            </div>
          </div>
        )}
      </PageHeader>
      {hasNavigationInfo && (
        <div className="location-profile__navigation-info">
          {location.entities?.info && (
            <LocationAdditionalInfoCard locationInfo={location.entities?.info} />
          )}
          {location.entities?.latitude && location.entities?.longitude && (
            <NavigateButton
              latitude={location.entities?.latitude}
              longitude={location.entities?.longitude}
            />
          )}
        </div>
      )}

      <SectionHeader header="Osoby" Icon={SmileyFaceIcon} />
      {location.entities?.people.map((person) => {
        return (
          <PersonCard
            key={person.id}
            name={person.name}
            personCode={person.code}
            locationFullName={person.location.name}
            personId={person.id}
          />
        );
      })}
      {hasVisitSummaries && (
        <VisitSummariesSection visitSummaries={location.entities?.visitSummaries || []} />
      )}
    </>
  );
};

export default LocationProfile;
