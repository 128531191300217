import { FC, useState } from "react";

interface ExpandableContentProps {
  content: string;
  maxTextLength?: number;
}

const ExpandableContent: FC<ExpandableContentProps> = ({ content, maxTextLength = 85 }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);
  const showExpandLink = content.length > maxTextLength;
  const displayContent =
    expanded || content.length <= maxTextLength ? content : `${content.slice(0, maxTextLength)}...`;

  return (
    <div className="expandable-content" role="button" onClick={toggleExpanded}>
      <p className="expandable-content-text">
        {displayContent}
        {showExpandLink && (
          <span className="expandable-text-toggle">{expanded ? " Zwiń" : " Rozwiń"}</span>
        )}
      </p>
    </div>
  );
};

export default ExpandableContent;
