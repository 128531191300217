import { FC } from "react";

import { Trip, TripGroup } from "../../../types/Trip";
import ActiveTripGroupCard from "../../molecules/ActiveTripGroupCard/ActiveTripGroupCard";

type TripGroupsListProps = {
  trips: Trip[];
};

const TripGroupsList: FC<TripGroupsListProps> = ({ trips }) => (
  <div className="card-list">
    {trips && trips.length > 0 ? (
      trips.map((trip: Trip) => (
        <div className="active-trip-group-list" key={trip.id}>
          <div className="active-trip-group-list__groups">
            {trip.groups.map((group: TripGroup, groupIndex: number) => (
              <ActiveTripGroupCard
                key={group.id}
                id={group.id}
                index={groupIndex + 1}
                volunteers={group.volunteers}
                locations={group.locations}
              />
            ))}
          </div>
        </div>
      ))
    ) : (
      <div className="active-trip__no-active-trips">
        <h3 className="active-trip__no-active-trips__header">Hmm...</h3>
        <p className="active-trip__no-active-trips__message">
          Wygląda na to, że aktualnie nie ma zaplanowanych wyjazdów.
        </p>
      </div>
    )}
  </div>
);

export default TripGroupsList;
