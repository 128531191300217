import { FC, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import PageHeader from "../../components/atoms/PageHeader/PageHeader";
import PersonalDataSection from "../../components/atoms/PersonalDataSection/PersonalDataSection";
import PackagesSection from "../../components/molecules/PackagesSection/PackagesSection";
import ItemRequestsNotAccepted from "../../components/molecules/PersonProfileRequestsNotAccepted/PersonItemRequestsNotAccepted";
import ItemRequestsSection from "../../components/organisms/ItemRequestsSection/ItemRequestsSection";
import LocationSection from "../../components/organisms/LocationSection/LocationSection";
import VisitSummariesSection from "../../components/organisms/VisitSummariesSection/VisitSummariesSection";
import { AppContext, StatusEnum } from "../../context/AppContext";

const PersonProfile: FC = () => {
  const { id } = useParams();
  const { fetchPerson, person } = useContext(AppContext);
  const isLoading = person.responseStatus === StatusEnum.Pending;
  const requestsStatus = person.entities?.requestsStatus;
  const hasPackage = person.entities?.packedPackages && person.entities.packedPackages.length > 0;
  const hasVisitSummaries =
    person.entities?.visitSummaries && person.entities.visitSummaries.length > 0;

  useEffect(() => {
    id && fetchPerson(id);
  }, [id]);

  return (
    <div className="person-profile">
      <PageHeader>
        {isLoading ? (
          <div>Ładujemy dane...</div>
        ) : (
          person.entities && (
            <PersonalDataSection
              isBigVariant
              name={person.entities.name}
              personCode={person.entities.code}
              phoneNumber={person.entities.phoneNumber}
            />
          )
        )}
      </PageHeader>
      {person.entities && <LocationSection location={person.entities?.location} />}
      {hasPackage && <PackagesSection packages={person.entities?.packedPackages || []} />}
      {hasVisitSummaries && (
        <VisitSummariesSection visitSummaries={person.entities?.visitSummaries || []} />
      )}
      {requestsStatus === "red" ? <ItemRequestsNotAccepted /> : <ItemRequestsSection />}
    </div>
  );
};

export default PersonProfile;
