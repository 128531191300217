import { useQuery } from "@tanstack/react-query";

import { Trip } from "../types/Trip";
import axiosClient from "./client";
import { endpoints } from "./endpoints";

export const fetchTrip = async () => {
  const { data } = await axiosClient.get<Trip[]>(endpoints.fetchActiveTrip);
  return data;
};

export const useActiveTrip = () => {
  return useQuery({
    queryKey: ["trip"],
    queryFn: fetchTrip,
  });
};
