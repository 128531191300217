import { FC, useContext, useEffect } from "react";

import { AppContext, StatusEnum } from "../../../context/AppContext";
import { CategoryIconName } from "../../../types/CategoryIconName";
import Button, { ButtonTypeEnum } from "../../atoms/Button/Button";
import CategoryItem from "../../atoms/CategoryItem/CategoryItem";

interface CategoryItemListProps {
  onCategoryClick: (
    id: number,
    iconName: CategoryIconName,
    name: string,
    availableSizes: string[]
  ) => void;
}

const CategoryItemList: FC<CategoryItemListProps> = ({ onCategoryClick }) => {
  const { fetchCategories, categories } = useContext(AppContext);
  const isLoading = categories.responseStatus === StatusEnum.Pending;
  const error = categories.responseStatus === StatusEnum.Rejected;

  useEffect(() => {
    fetchCategories();
  }, []);

  if (isLoading) return <div>Ładowanie...</div>;

  if (error) return <div>Nie mogliśmy załadować kategorii 😿, Spróbuj odświeżyć stronę...</div>;

  if (!categories.entities.length) return <div>Kategorie nie zostały zdefiniowane... 🤷‍♀️</div>;

  return (
    <div className="category-list">
      {categories.entities.map(({ id, name, iconName, availableSizes }) => (
        <Button
          onClick={() => onCategoryClick(id, iconName, name, availableSizes)}
          variant={ButtonTypeEnum.CategoryTile}
          key={id}
        >
          <CategoryItem
            categoryIconName={iconName}
            categoryTitle={name}
            categorySizes={availableSizes}
          />
        </Button>
      ))}
    </div>
  );
};

export default CategoryItemList;
