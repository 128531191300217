import { FC } from "react";

import Tag from "../Tag/Tag";

interface ActiveTripDataSectionProps {
  tripDate?: string;
}

const ActiveTripDataSection: FC<ActiveTripDataSectionProps> = ({ tripDate }) => {
  return (
    <div className="active-trip-data-section">
      {tripDate && <Tag tagData={tripDate} />}
      <h1>Wyjazd</h1>
    </div>
  );
};

export default ActiveTripDataSection;
