import { ChangeEvent, FC, useContext, useEffect, useState } from "react";

import Input from "../../components/atoms/Input/Input";
import NoResultCard from "../../components/atoms/NoResultCard/NoResultCard";
import PageHeader from "../../components/atoms/PageHeader/PageHeader";
import SectionHeader from "../../components/atoms/SectionHeader/SectionHeader";
import { MagnifyingGlass } from "../../components/icons/Icons";
import LocationCard from "../../components/molecules/LocationCard/LocationCard";
import PersonCard from "../../components/molecules/PersonCard/PersonCard";
import { AppContext, StatusEnum } from "../../context/AppContext";
import { LocationOption } from "../../types/Location";
import { Person } from "../../types/Person";
import { SearchableRecord } from "../../types/Person";
import { simpleNormalize } from "../../utils/stringUtils";

const Search: FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allRecords, setAllRecords] = useState<SearchableRecord[]>([]);
  const [displayedResults, setDisplayedResults] = useState<SearchableRecord[]>([]);

  const { people, locations, fetchLocations, fetchPeople } = useContext(AppContext);

  useEffect(() => {
    fetchPeople();
    fetchLocations();
  }, []);

  const isLoading =
    people.responseStatus === StatusEnum.Pending || locations.responseStatus === StatusEnum.Pending;

  useEffect(() => {
    if (!isLoading) {
      const wrappedPeople: SearchableRecord[] = people.entities.map((person) => ({
        type: "Person",
        payload: person,
        lookupTerms: [simpleNormalize(person.name), person.code],
      }));
      const wrappedLocations: SearchableRecord[] = locations.entities.map((location) => ({
        type: "Location",
        payload: location,
        lookupTerms: [simpleNormalize(location.fullName)],
      }));
      const combinedRecords = [...wrappedPeople, ...wrappedLocations].sort((a, b) => {
        return a.lookupTerms[0].localeCompare(b.lookupTerms[0]);
      });

      setAllRecords(combinedRecords);
      setDisplayedResults(combinedRecords);
    }
  }, [people, locations, isLoading]);

  useEffect(() => {
    if (searchTerm === "") {
      setDisplayedResults(allRecords);
    } else {
      const normalizedSearchTerm = simpleNormalize(searchTerm);
      const filteredResults = allRecords.filter((record) =>
        record.lookupTerms.some((field) => field.includes(normalizedSearchTerm))
      );
      setDisplayedResults(filteredResults);
    }
  }, [searchTerm]);

  const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const renderCard = (record: SearchableRecord) => {
    if (record.type === "Person") {
      const person = record.payload as Person;
      return (
        <PersonCard
          key={`person-${person.id}`}
          name={person.name}
          personCode={person.code}
          locationFullName={person.location.fullName}
          personId={person.id}
        />
      );
    } else if (record.type === "Location") {
      const location = record.payload as LocationOption;
      return (
        <LocationCard
          key={`location-${location.id}`}
          id={location.id}
          fullName={location.fullName}
          personCount={location.people.length}
        />
      );
    }
  };
  return (
    <>
      <PageHeader heading="Wyszukiwarka">
        <form autoComplete="off">
          <div className="search__input-wrapper">
            <Input
              label=""
              type="text"
              id="search"
              name="search"
              value={searchTerm}
              autoComplete="off"
              onChange={handleSearchTermChange}
              clearButton={true}
            />
          </div>
        </form>
      </PageHeader>

      <SectionHeader header="Wyniki" Icon={MagnifyingGlass} />

      {isLoading ? (
        <div>Loading...</div>
      ) : displayedResults.length > 0 ? (
        <div>{displayedResults.map((record) => renderCard(record))}</div>
      ) : (
        <NoResultCard />
      )}
    </>
  );
};

export default Search;
